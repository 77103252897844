<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Audit</h1>
      <div class="btns-container">
        <VasionButton
          id="share"
          :classProp="'secondary'"
          :isDisabled="disableRunButtons"
          :isSubmit="true"
          :ripple="true"
          @vasionButtonClicked="generatePDF"
        >
          Share
        </VasionButton>
        <VasionButton
          id="run-report"
          :classProp="'primary'"
          :isDisabled="disableRunButtons"
          :isSubmit="true"
          :ripple="true"
          @vasionButtonClicked="runAuditReport"
        >
          Run Report
        </VasionButton>
        <VasionButton
          id="save-report"
          class="last-btn"
          :classProp="'primary'"
          :isDisabled="disableRunButtons"
          @vasionButtonClicked="saveReport()"
        >
          Save Report
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <form @submit.prevent="runAuditReport">
        <table>
          <tr>
            <td class="input-column">
              <table>
                <tr>
                  <td>
                    <VasionDateTimeField
                      v-model="fromDate"
                      label="FROM DATE"
                      type="date"
                      :required="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <VasionDateTimeField
                      v-model="toDate"
                      label="THROUGH DATE"
                      type="date"
                      :required="true"
                    />
                  </td>
                </tr>
              </table>
            </td>
            <td class="button-column">
              <VasionButton
                v-model="conjunction"
                :isFullWidth="true"
                :classProp="calculatedAndClass()"
                @vasionButtonClicked="toggleConjunction(true)"
              >
                And
              </VasionButton>
              <VasionButton
                v-model="conjunction"
                :isFullWidth="true"
                :classProp="calculatedOrClass()"
                @vasionButtonClicked="toggleConjunction(false)"
              >
                Or
              </VasionButton>
            </td>
            <td class="input-column left-padding">
              <table>
                <tr>
                  <td>
                    <VasionDropList
                      v-slot="slotItem"
                      v-model="selectedUser"
                      :dataArray="userList"
                      title="USER NAME"
                      inputType="plain-list"
                      valueName="value"
                      displayName="name"
                      placeholder="User Name"
                      width="100%"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                  </td>
                  <td>
                    <VasionDropList
                      v-slot="slotItem"
                      v-model="selectedAction"
                      :dataArray="auditActionsList"
                      title="ACTION"
                      inputType="plain-list"
                      valueName="ActionID"
                      displayName="name"
                      placeholder="Action"
                      width="100%"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <VasionInput
                      id="memo"
                      v-model="memo"
                      inputType="top-white"
                      placeholder="Enter Memo"
                      title="MEMO"
                      width="100%"
                    />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </form>
      <div v-if="auditReport.tableHeaders">
        <div v-if="totalRowCount > 0">
          <div class="md-layout">
            <div>
              <md-button
                id="btnFirstPage"
                class="md-icon-button md-dense"
                title="First"
                :disabled="disablePreviousButtons"
                @click="goToFirstPage()"
              >
                <VasionFirstPageIcon :class="{ 'vasion-icon-disabled': disablePreviousButtons }" />
              </md-button>
              <md-button
                id="btnPreviousPage"
                class="md-icon-button md-dense"
                title="Previous"
                :disabled="disablePreviousButtons"
                @click="goToPreviousPage()"
              >
                <VasionPreviousPageIcon :class="{ 'vasion-icon-disabled': disablePreviousButtons }" />
              </md-button>
            </div>
            <VasionInput
              v-model="pageDisplay"
              :readOnly="true"
              inputType="blank-white"
              width="94"
            />
            <div>
              <md-button
                id="btnNextPage"
                class="md-icon-button md-dense"
                title="Next"
                :disabled="disableNextButtons"
                @click="goToNextPage()"
              >
                <VasionNextPageIcon :class="{ 'vasion-icon-disabled': disableNextButtons }" />
              </md-button>
              <md-button
                id="btnLastPage"
                class="md-icon-button md-dense"
                title="Last"
                :disabled="disableNextButtons"
                @click="goToLastPage()"
              >
                <VasionLastPageIcon :class="{ 'vasion-icon-disabled': disableNextButtons }" />
              </md-button>
            </div>
          </div>
          <div class="audit-table-container vasion-html-table-default">
            <VasionTable id="audit-report-table" :headerColumns="auditReport.tableHeaders" :tableRows="displayRows" />
          </div>
        </div>
        <div v-else>
          No data for the given report configuration
        </div>
      </div>
      <div v-else class="vasion-run-report-prompt">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Run a Report to view entries
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheAuditReport',
  components: {
    Loading,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'AuditReportConfig' || from.name === 'TheSavedAuditReport') {
        vm.setReportValues()
      }
    })
  },
  data: function () {
    return {
      conjunction: true,
      currentPageNumber: 1,
      displayRowMaxChunk: 25,
      fromDate: '',
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      memo: '',
      selectedAction: '',
      selectedUser: '',
      toDate: '',
    }
  },
  computed: {
    auditActions() { return this.$store.state.reporting.auditActions },
    auditActionsList() {
      const array = this.auditActions.map((f) => {
        return {
          name: f.ActionDescription,
          ActionID: f.ActionID,
        }
      })

      array.unshift({
        name: '',
        ActionID: '',
      })
      return array
    },
    auditReport() { return this.$store.state.reporting.auditReport },
    auditReportValues() { return this.$store.state.reporting.auditReportData },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    disableRunButtons() { return this.toDate === '' || this.fromDate === '' },
    displayRows() {
      const startIndex = (this.currentPageNumber - 1) * this.displayRowMaxChunk
      const endIndex = this.currentPageNumber >= this.totalPages ? this.totalRowCount : this.currentPageNumber * this.displayRowMaxChunk

      return this.auditReport.tableRows.slice(startIndex, endIndex)
    },
    editingAuditReport() { return this.$store.state.reporting.editingAuditReport },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    userReports() { return this.$store.state.reporting.userReports },
    users() { return this.$store.state.common.users },
    userList() {
      const array = this.users.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })

      array.unshift({
        name: '',
        value: '',
      })
      return array
    },
    totalPages() {
      if (this.totalRowCount === 0) {
        return 0
      } else if (this.totalRowCount % this.displayRowMaxChunk === 0) {
        return this.totalRowCount / this.displayRowMaxChunk
      }

      return Math.floor(this.totalRowCount / this.displayRowMaxChunk) + 1
    },
    totalRowCount() { return this.auditReport.tableRows ? this.auditReport.tableRows.length : 0 },
  },
  watch: {
    fromDate: function(val) {
      if (val > this.toDate) {
        this.toDate = ''
      }
    }
  },
  created() {
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat

    this.$store.dispatch('reporting/resetReports')
    this.$store.dispatch('common/getUsers')
    this.$store.dispatch('reporting/getAuditActions')
    this.$store.dispatch('reporting/loadReports', true)
  },
  methods: {
    buildPayload() {
      const payload = this.editingAuditReport ? this.auditReportValues : {}
      if (this.disableRunButtons === false) {
        payload.UserID = this.selectedUser ? this.selectedUser.value : null
        payload.ActionID = this.selectedAction ? this.selectedAction.ActionID : null
        payload.DateFrom = this.fromDate
        payload.DateTo = this.toDate
        payload.Memo = this.memo
        payload.AndSearch = this.conjunction
      }

      return payload
    },
    calculatedAndClass() { return this.conjunction ? 'secondary-toggled' : 'secondary' },
    calculatedOrClass() { return !this.conjunction ? 'secondary-toggled' : 'secondary' },
    async generatePDF() {
      this.isLoading = true
      const payload = this.buildPayload()

      if (payload) {
        await this.$store.dispatch('reporting/generateAuditReportPDF', payload)
      }
      await this.$amplitude.trackEvent('Report Shared', {
        'reportType': 'Audit',
      })
      this.isLoading = false
    },
    goToFirstPage() { this.currentPageNumber = 1 },
    goToLastPage() { this.currentPageNumber = this.totalPages },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.currentPageNumber += 1
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.currentPageNumber -= 1
      }
    },
    async runAuditReport() {
      this.isLoading = true
      const payload = this.buildPayload()

      if (payload) {
        await this.$store.dispatch('reporting/runAuditReport', payload)
        this.currentPageNumber = 1
      }

      await this.$amplitude.trackEvent('Report Run', {reportType: 'Audit'})

      this.isLoading = false
    },
    saveReport() {
      const payload = this.buildPayload()
      if (!this.editingAuditReport) {
        payload.AuditReportID = 0
      }
      this.$store.dispatch('reporting/setAuditReportData', payload)
      this.$router.push({ name: 'AuditReportConfig' })
    },
    setReportValues() {
      const auditReportValues = this.$store.state.reporting.auditReportData
      this.fromDate = auditReportValues.DateFrom.split('T')[0]
      this.toDate = auditReportValues.DateTo.split('T')[0]
      this.selectedUser = this.users.find(a => a.value === auditReportValues.UserID)
      this.memo = auditReportValues.Memo
      this.selectedAction = this.auditActionsList.find(action => { return action.ActionID === auditReportValues.ActionID })
      this.conjunction = auditReportValues.AndSearch
    },
    toggleConjunction(value) { this.conjunction = value },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .min548 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 164px;
  }

  .toggle-button-div {
    width: 145px;
    min-width: 145px;
  }

  .date {
    margin-top: 0;
  }

  .layout-gutter-right {
    margin-right: 30px;
  }

  .and-or {
    width: 100%;

    button {
      width: 100%;
    }
  }

  table {
    width: 100%;
  }

  td {
    height: 80px;
  }

  .input-column {
    width: 42%
  }

  .button-column {
    padding-right: 30px;
    padding-left: 30px;
  }

  .left-padding {
    padding-left: 5px;
  }

.audit-table-container {
  height: calc(100vh - 424px);
  overflow: auto;
  text-align: left;
}  
</style>
